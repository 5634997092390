import React from "react";

function Policy() {
  return (
    <div>
      <p>
        <b>
          1. 개인정보의 처리목적 &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI
          로또 추첨기')은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며,
          다음의 목적 이외의 용도로는 이용하지 않습니다.{" "}
        </b>
        <br />- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인
        식별․인증, 회원자격 유지․관리, 물품 또는 서비스 공급에 따른 금액 결제,
        물품 또는 서비스의 공급․배송 등
      </p>
      <p>
        <b>
          2. 개인정보의 처리 및 보유기간 ① &lt;byun&gt;('AI로또 추첨기'이하 회차
          분석 AI 로또 추첨기')은(는) 정보주체로부터 개인정보를 수집할 때
          동의받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보
          보유․이용기간 내에서 개인정보를 처리․보유합니다.{" "}
        </b>
        <br />
        ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다. <br />
        - 고객 가입 및 관리: 서비스 이용계약 또는 회원가입 해지시까지, 다만
        채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지 <br />-
        전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록: 5년
      </p>
      <p>
        <b>
          3. 개인정보의 제3자 제공 &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI
          로또 추첨기')은(는) 정보주체의 별도 동의, 법률의 특별한 규정 등
          개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를 제3자에게
          제공하지 않습니다.{" "}
        </b>
      </p>
      <p>
        <b>
          4. 개인정보처리의 위탁 ① &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI
          로또 추첨기')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이
          개인정보 처리업무를 외부에 위탁하고 있습니다.{" "}
        </b>
        <br />
        - A/S 센터 운영 <br />
        ․위탁받는 자 (수탁자) : byun <br />
        ․위탁하는 업무의 내용 : 고객 대상 제품 A/S 제공 <br />②
        &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI 로또 추첨기')은(는)
        위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외
        개인정보 처리금지, 재위탁 제한, 수탁자에 대한 관리․감독, 책임에 관한
        사항을 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
        감독하고 있습니다.
      </p>
      <p>
        <b>
          5. 정보주체와 법정대리인의 권리․의무 및 행사방법 정보주체는
          &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI 로또 추첨기')에 대해
          언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.{" "}
        </b>
        <br />
        1. 개인정보 열람요구
        <br />
        2. 개인정보에 오류 등이 있을 경우 정정 요구 <br />
        3. 삭제요구 <br />
        4. 처리정지 요구
      </p>
      <p>
        <b>
          6. 처리하는 개인정보 항목 &lt;byun&gt;('AI로또 추첨기'이하 회차 분석
          AI 로또 추첨기')은(는) 다음의 개인정보 항목을 처리하고 있습니다.{" "}
        </b>
        <br />- 성명, 생년월일, 주소, 전화번호, 휴대전화번호, 성별, 이메일주소,
        신용카드번호, 은행계좌번호 등 결제정보
      </p>
      <p>
        <b>
          7. 개인정보의 파기 ① &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI
          로또 추첨기')은(는) 개인정보 보유기간의 경과, 처리목적 달성 등
          개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
          파기합니다.{" "}
        </b>
        <br />
        ② &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI 로또 추첨기')은(는)
        다음의 방법으로 개인정보를 파기합니다. <br />
        - 전자적 파일 : 파일 삭제 및 디스크 등 저장매체 포맷
        <br />- 수기(手記) 문서 : 분쇄하거나 소각
      </p>
      <p>
        <b>
          8. 개인정보의 안전성 확보조치 &lt;byun&gt;('AI로또 추첨기'이하 회차
          분석 AI 로또 추첨기')은(는) 개인정보의 안전성 확보를 위해 다음과 같은
          조치를 취하고 있습니다.{" "}
        </b>
        <br />
        - 관리적 조치 : 내부관리계획 수립․시행, 직원․종업원 등에 대한 정기적
        교육 <br />
        - 기술적 조치 : 개인정보처리시스템(또는 개인정보가 저장된 컴퓨터)의
        비밀번호 설정 등 접근권한 관리, 백신소프트웨어 등 보안프로그램 설치,
        개인정보가 저장된 파일의 암호화 <br />- 물리적 조치 : 개인정보가
        저장․보관된 장소의 시건, 출입통제 등
      </p>
      <p>
        <b>9. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항 </b>{" "}
        <br />
        ① &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI 로또 추첨기')은(는)
        이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고
        수시로 불러오는 ‘쿠기(cookie)’를 사용합니다. <br />
        ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
        브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
        하드디스크에 저장되기도 합니다. <br />
        가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 <br />
        대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 <br />
        이용자에게 최적화된 정보 제공을 위해 사용됩니다. <br />
        나. 쿠키의 설치∙운영 및 거부: 웹브라우저 상단의 도구>인터넷
        옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.{" "}
        <br />
        다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
        있습니다.
      </p>
      <p>
        <b>
          {" "}
          10. 개인정보 보호책임자 &lt;byun&gt;('AI로또 추첨기'이하 회차 분석 AI
          로또 추첨기')은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기
          위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.{" "}
        </b>{" "}
        <br />
        ▶ 개인정보 보호책임자 (사업주 또는 대표자) <br />
        성명: 표미선 직책: 개발자 및 대표 <br />
        연락처: 01053259131, bambwc20@gmail.com
      </p>
      <p>
        <b>11. 개인정보 처리방침 변경 </b> <br />이 개인정보 처리방침은
        시행일로부터 적용되며, 볍령 및 방침에 따른 변경내용의 추가, 삭제 및
        정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할
        것입니다.
      </p>
      <p>
        <b>12. LICENSED APPLICATION END USER LICENSE AGREEMENT </b>
        <br />
        Apps made available through the App Store are licensed, not sold, to
        you. Your license to each App is subject to your prior acceptance of
        either this Licensed Application End User License Agreement (“Standard
        EULA”), or a custom end user license agreement between you and the
        Application Provider (“Custom EULA”), if one is provided. Your license
        to any Apple App under this Standard EULA or Custom EULA is granted by
        Apple, and your license to any Third Party App under this Standard EULA
        or Custom EULA is granted by the Application Provider of that Third
        Party App. Any App that is subject to this Standard EULA is referred to
        herein as the “Licensed Application.” The Application Provider or Apple
        as applicable (“Licensor”) reserves all rights in and to the Licensed
        Application not expressly granted to you under this Standard EULA.
        <br /> <br />
        a. Scope of License: Licensor grants to you a nontransferable license to
        use the Licensed Application on any Apple-branded products that you own
        or control and as permitted by the Usage Rules. The terms of this
        Standard EULA will govern any content, materials, or services accessible
        from or purchased within the Licensed Application as well as upgrades
        provided by Licensor that replace or supplement the original Licensed
        Application, unless such upgrade is accompanied by a Custom EULA. Except
        as provided in the Usage Rules, you may not distribute or make the
        Licensed Application available over a network where it could be used by
        multiple devices at the same time. You may not transfer, redistribute or
        sublicense the Licensed Application and, if you sell your Apple Device
        to a third party, you must remove the Licensed Application from the
        Apple Device before doing so. You may not copy (except as permitted by
        this license and the Usage Rules), reverse-engineer, disassemble,
        attempt to derive the source code of, modify, or create derivative works
        of the Licensed Application, any updates, or any part thereof (except as
        and only to the extent that any foregoing restriction is prohibited by
        applicable law or to the extent as may be permitted by the licensing
        terms governing use of any open-sourced components included with the
        Licensed Application).
        <br /> <br />
        b. Consent to Use of Data: You agree that Licensor may collect and use
        technical data and related information—including but not limited to
        technical information about your device, system and application
        software, and peripherals—that is gathered periodically to facilitate
        the provision of software updates, product support, and other services
        to you (if any) related to the Licensed Application. Licensor may use
        this information, as long as it is in a form that does not personally
        identify you, to improve its products or to provide services or
        technologies to you.
        <br /> <br />
        c. Termination. This Standard EULA is effective until terminated by you
        or Licensor. Your rights under this Standard EULA will terminate
        automatically if you fail to comply with any of its terms.
        <br /> <br />
        d. External Services. The Licensed Application may enable access to
        Licensor’s and/or third-party services and websites (collectively and
        individually, "External Services"). You agree to use the External
        Services at your sole risk. Licensor is not responsible for examining or
        evaluating the content or accuracy of any third-party External Services,
        and shall not be liable for any such third-party External Services. Data
        displayed by any Licensed Application or External Service, including but
        not limited to financial, medical and location information, is for
        general informational purposes only and is not guaranteed by Licensor or
        its agents. You will not use the External Services in any manner that is
        inconsistent with the terms of this Standard EULA or that infringes the
        intellectual property rights of Licensor or any third party. You agree
        not to use the External Services to harass, abuse, stalk, threaten or
        defame any person or entity, and that Licensor is not responsible for
        any such use. External Services may not be available in all languages or
        in your Home Country, and may not be appropriate or available for use in
        any particular location. To the extent you choose to use such External
        Services, you are solely responsible for compliance with any applicable
        laws. Licensor reserves the right to change, suspend, remove, disable or
        impose access restrictions or limits on any External Services at any
        time without notice or liability to you.
        <br /> <br />
        e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE
        LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES
        PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS"
        AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND,
        AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT
        TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED,
        OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES
        AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF
        FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND
        OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION
        OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL
        CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE
        DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
        REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
        IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A
        CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
        <br /> <br />
        f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO
        EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL,
        SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING,
        WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS
        INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF
        OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION,
        HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT,
        OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
        LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall
        Licensor’s total liability to you for all damages (other than as may be
        required by applicable law in cases involving personal injury) exceed
        the amount of fifty dollars ($50.00). The foregoing limitations will
        apply even if the above stated remedy fails of its essential purpose.
        <br /> <br />
        g. You may not use or otherwise export or re-export the Licensed
        Application except as authorized by United States law and the laws of
        the jurisdiction in which the Licensed Application was obtained. In
        particular, but without limitation, the Licensed Application may not be
        exported or re-exported (a) into any U.S.-embargoed countries or (b) to
        anyone on the U.S. Treasury Department's Specially Designated Nationals
        List or the U.S. Department of Commerce Denied Persons List or Entity
        List. By using the Licensed Application, you represent and warrant that
        you are not located in any such country or on any such list. You also
        agree that you will not use these products for any purposes prohibited
        by United States law, including, without limitation, the development,
        design, manufacture, or production of nuclear, missile, or chemical or
        biological weapons.
        <br /> <br />
        h. The Licensed Application and related documentation are "Commercial
        Items", as that term is defined at 48 C.F.R. §2.101, consisting of
        "Commercial Computer Software" and "Commercial Computer Software
        Documentation", as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R.
        §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R.
        §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer
        Software and Commercial Computer Software Documentation are being
        licensed to U.S. Government end users (a) only as Commercial Items and
        (b) with only those rights as are granted to all other end users
        pursuant to the terms and conditions herein. Unpublished-rights reserved
        under the copyright laws of the United States.
        <br /> <br />
        i. Except to the extent expressly provided in the following paragraph,
        this Agreement and the relationship between you and Apple shall be
        governed by the laws of the State of California, excluding its conflicts
        of law provisions. You and Apple agree to submit to the personal and
        exclusive jurisdiction of the courts located within the county of Santa
        Clara, California, to resolve any dispute or claim arising from this
        Agreement. If (a) you are not a U.S. citizen; (b) you do not reside in
        the U.S.; (c) you are not accessing the Service from the U.S.; and (d)
        you are a citizen of one of the countries identified below, you hereby
        agree that any dispute or claim arising from this Agreement shall be
        governed by the applicable law set forth below, without regard to any
        conflict of law provisions, and you hereby irrevocably submit to the
        non-exclusive jurisdiction of the courts located in the state, province
        or country identified below whose law governs:
        <br /> <br />
        If you are a citizen of any European Union country or Switzerland,
        Norway or Iceland, the governing law and forum shall be the laws and
        courts of your usual place of residence.
        <br /> <br />
        Specifically excluded from application to this Agreement is that law
        known as the United Nations Convention on the International Sale of
        Goods.
      </p>
    </div>
  );
}

export default Policy;
