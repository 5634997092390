//essentials
import React, { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";

//Modules
import { analytics } from "../Modules/Fbase";

function Download() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const { source } = useParams();

  const iosStoreURL =
    "https://apps.apple.com/kr/app/ai%EB%A1%9C%EB%98%90-%EC%B6%94%EC%B2%A8%EA%B8%B0-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9E%90%EB%8F%99-%EB%A1%9C%EB%98%90-%EC%B6%94%EC%B2%A8%EA%B8%B0/id1665183392";
  const androidStoreURL =
    "https://play.google.com/store/apps/details?id=com.moowang.ailotto";

  useEffect(() => {
    document.body.style = "background: #F9F8F5;";

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    if (isAndroid) {
      window.location.href = androidStoreURL;
    } else if (isIOS) {
      window.location.href = iosStoreURL;
    } else {
      window.location.href = androidStoreURL;
    }

    logEvent(analytics, "LinkToAppstore", {
      source: source ?? "unknown",
    });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        height: windowDimensions.height,
        width: windowDimensions.width,
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
          }}
        >
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            앱스토어 연결중...
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
