import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Home() {
  let navigate = useNavigate();
  const { source } = useParams();

  useEffect(() => {
    navigate(`/app/${source ?? "unknown"}`);
  }, []);

  return <></>;
}

export default Home;
