//essentials
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Routes
import Policy from "./Routes/Policy";
import Home from "./Routes/Home";
import Download from "./Routes/Download";
import NotFound from "./Routes/NotFound";
import ProxyDownload from "./Routes/ProxyDownload";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:source" element={<Home />} />

        <Route path="/store" element={<Download />} />
        <Route path="/store/:source" element={<Download />} />

        <Route path="/app" element={<ProxyDownload />} />
        <Route path="/app/:source" element={<ProxyDownload />} />

        <Route path="/policy" element={<Policy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
