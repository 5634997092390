import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";

//Image
import Logo from "../Image/Main.png";
import Cover from "../Image/Cover.png";

//Modules
import { analytics } from "../Modules/Fbase";

function ProxyDownload() {
  const { source } = useParams();
  const ref = useRef(null);
  const [proWidth, setProWidth] = useState(0);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const goToStore = () => {
    const iosStoreURL =
      "https://apps.apple.com/kr/app/ai%EB%A1%9C%EB%98%90-%EC%B6%94%EC%B2%A8%EA%B8%B0-%EC%9D%B8%EA%B3%B5%EC%A7%80%EB%8A%A5-%EC%9E%90%EB%8F%99-%EB%A1%9C%EB%98%90-%EC%B6%94%EC%B2%A8%EA%B8%B0/id1665183392";
    const androidStoreURL =
      "https://play.google.com/store/apps/details?id=com.moowang.ailotto";

    if (isAndroid) {
      window.location.href = androidStoreURL;
    } else if (isIOS) {
      window.location.href = iosStoreURL;
    } else {
      window.location.href = androidStoreURL;
    }

    logEvent(analytics, "LinkToProxyAppStore", {
      source: source ?? "unknown",
    });
  };

  useEffect(() => {
    logEvent(analytics, "ViewToProxyAppStore", {
      source: source ?? "unknown",
    });

    document.body.style = "background: #F9F8F5;";

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useLayoutEffect(() => {
    setProWidth(ref.current.offsetWidth);
  }, []);

  return (
    <div
      style={{
        width: windowDimensions.width,
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 60,
        paddingBottom: 60,
      }}
    >
      <div>
        <div
          style={{
            height: 90,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="Main Logo"
            style={{
              width: 90,
              height: 90,
            }}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            marginTop: 12,
          }}
        >
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
              marginBottom: 6,
              color: "#62AC64",
            }}
          >
            AI로또 매니저
          </div>
          <div
            style={{
              fontSize: 18,
              letterSpacing: -0.35,
              lineHeight: 1.3,
              marginBottom: 16,
            }}
            ref={ref}
          >
            무료로 AI가 분석해준 로또 추첨 번호를 받을 수 있습니다! <br />
            또한 다양한 로또 추첨, 분석, 관리 도구들을 이용해보세요!
          </div>

          <div
            style={{
              marginBottom: 32,
            }}
          >
            <button
              class="btn btn-primary"
              type="button"
              style={{
                backgroundColor: "#62AC64",
                borderWidth: 0,
                fontWeight: "bold",
                margin: 0,
                display: "block",
                width: "100%",
                padding: 12,
              }}
              onClick={() => {
                goToStore();
              }}
            >
              앱 다운로드
            </button>
          </div>

          <div
            style={{
              margin: 0,
              display: "block",
              width: "100%",
            }}
          >
            <img
              src={Cover}
              alt="Cover"
              style={{
                width: proWidth,
                borderRadius: 16,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProxyDownload;
